import React from "react";
import Logo from "../img/1.png";

const Modal = ({ showModal, closeModal, children, clinic_id }) => {
  return (
    <>
      {showModal && (
        <div className="fixed inset-0 bg-white flex items-center justify-center">
          <div className="flex flex-col gap-4 justify-center items-center bg-white p-8 rounded-lg">
            {(clinic_id === "65a3a97b623c3dbb797a8f98" ||
              clinic_id === "65a3ab30623c3dbb797a8f9a" ||
              clinic_id === "65a3b3a4623c3dbb797a8f9c") && (
              <div className="flex flex-col items-center justify-center">
                <img
                  src={Logo}
                  alt="logo"
                  className="w-[15rem] -mt-8 rounded-lg"
                />
                {children}
              </div>
            )}

            {/* <-------- pleasanton ------->  */}

            {clinic_id === "65a3ab30623c3dbb797a8f9a" && (
              <a
                href="https://app.petdesk.com/request-appointment/animal-medical-center-of-pleasanton?placeGUID=4cb5d300-4064-4dfd-8580-6a3f0e31e416"
                target="_blank"
                className=""
              >
                <img
                  src="https://petdesk.s3.amazonaws.com/images/apptrequestbutton.png"
                  alt="Request an Appointment"
                  className="ml-0"
                />
              </a>
            )}

            {/* <-------- BLVD ---------> */}

            {clinic_id === "65a3abeb623c3dbb797a8f9b" && (
              <div className="flex flex-col w-full text-center">
                <text className="p-4">Please check you email for results</text>
                <div className="flex flex-col justify-center items-center">
                  <text className="text-4xl font-bold">
                    September Dental Month Special
                  </text>
                  <text className="text-5xl font-bold">
                    $200 Off Dental Care!
                  </text>
                  <text>*Offer valid from Sept. 1-30 2024</text>
                </div>
                <a
                  href="https://blvdvet.net/appointments/#appointment-type"
                  target="_blank"
                  className="w-full mt-4 text-center bg-blue-700 z-10 text-white px-4 py-2 rounded-full"
                >
                  <text className="">Book an Appointment</text>
                </a>
                <a
                  href="https://blvdvet.net/"
                  target="_blank"
                  className="w-full mt-4 text-center bg-blue-700 z-10 text-white px-4 py-2 rounded-full"
                >
                  <text className="">Visit Clinic Website</text>
                </a>
              </div>
            )}

            {/* <------- VMCUC -------> */}

            {clinic_id === "66aac5ba721beda869ab64c1" && (
              <div className="flex flex-col w-full text-center">
                <text className="p-4">Please check you email for results</text>
                <img
                  src="https://askmila.app/wp-content/uploads/2024/08/Logo1.ai-1.png"
                  alt="logo"
                  className="w-96 my-8 rounded-lg"
                />
                {/* <div className="flex flex-col justify-center items-center">
                  <text className="text-4xl font-bold">
                    September Dental Month Special
                  </text>
                  <text className="text-5xl font-bold">
                    $200 Off Dental Care!
                  </text>
                  <text>*Offer valid from Sept. 1-30 2024</text>
                </div> */}
                <a
                  href="https://my.vitusvet.com/Appointments/schedule/dfe0c296-7f4f-4f85-92aa-61e004a2d93c"
                  target="_blank"
                  className="w-full mt-4 text-center bg-blue-700 z-10 text-white px-4 py-2 rounded-full"
                >
                  <text className="">Book an appointment online</text>
                </a>
                <a
                  href="https://unioncityvetmedcenter.com/"
                  target="_blank"
                  className="w-full mt-4 text-center bg-blue-700 z-10 text-white px-4 py-2 rounded-full"
                >
                  <text className="">Visit Clinic Website</text>
                </a>
              </div>
            )}

            {(clinic_id === "65a3a97b623c3dbb797a8f98" ||
              clinic_id === "65a3ab30623c3dbb797a8f9a" ||
              clinic_id === "65a3b3a4623c3dbb797a8f9c") && (
              <button
                onClick={closeModal}
                className="w-full mt-4 bg-blue-700 z-10 text-white px-4 py-2 rounded-full"
              >
                Return to Website
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
