import React, { useState, useEffect } from "react";

import "../styles/modal.css";

function ModalResponsePassword({ modalStatus, setModalStatus, message }) {
  return (
    <>
      {modalStatus === true && (
        <>
          <div className="notifications-container modal-main">
            <div className="success" style={{ width: "20%", height: "50%" }}>
              <div className="flex">
                <div className="success-prompt-wrap">
                  <p
                    className="success-prompt-heading"
                    style={{ textAlign: "center" }}
                  >
                    Error
                  </p>
                  <div className="success-prompt-prompt">
                    <p>{message}</p>
                  </div>
                  <div
                    className="success-button-container"
                    style={{ justifyContent: "center", display: "flex" }}
                  >
                    <button
                      onClick={() => {
                        setModalStatus(false);
                        // window.location.reload();
                      }}
                      type="button"
                      className="success-button-main"
                    >
                      Accept
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              setModalStatus(false);
              window.location.reload();
            }}
            className="opacity-25 fixed overflow-hidden inset-0 z-40 bg-black"
          ></div>
        </>
      )}
    </>
  );
}

export default ModalResponsePassword;
