// import "../styles/spinner.css";

// function Spinner() {
//   return (
//     <>
//       <div class="spinner-main">
//         <div className="hourglassBackground ">
//           <div className="hourglassContainer">
//             <div className="hourglassCurves"></div>
//             <div className="hourglassCapTop"></div>
//             <div className="hourglassGlassTop"></div>
//             <div className="hourglassSand"></div>
//             <div className="hourglassSandStream"></div>
//             <div className="hourglassCapBottom"></div>
//             <div className="hourglassGlass"></div>
//           </div>
//         </div>
//       </div>
//       <div className="opacity-25 fixed overflow-hidden inset-0 z-40 bg-black"></div>
//     </>
//   );
// }

// export default Spinner;

import React, { useState, useEffect } from "react";

const Spinner = () => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(true);
    }, 5000); // 5000 ms = 5 segundos

    // Limpiar el tiempo de espera si el componente se desmonta
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center h-80">
      <div className="w-16 h-16 flex justify-center items-center rounded-full animate-spin bg-gradient-to-br from-pink-500 via-blue-500 to-green-500">
        <div className="w-12 h-12 rounded-full animate-spin bg-white"></div>
      </div>
      {showMessage && (
        <p className="mt-4 text-gray-700 animate-fade-up font-bold">
          wait a moment...
        </p>
      )}
    </div>
  );
};

export default Spinner;
