import React, { useEffect, useState } from "react";
import globalVariables from "../globalVariables";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import Spinner from "../components/spinner";
import ModalResponse from "../components/modalResponse";
import "../styles/style.css";
import ModalResponsePassword from "../components/modalResponsePassword";

function RecoverPassword() {
  const { control, handleSubmit, reset, resetField } = useForm();

  const [step1Complete, setStep1Complete] = useState(false);
  const [step2Complete, setStep2Complete] = useState(false);
  const [email, setEmail] = useState("");

  const [modalStatus, setModalStatus] = useState(false);
  const [message, setMessage] = useState("");

  const onSubmitEmail = async (data) => {
    console.log(data);
    setEmail(data.email);

    try {
      const response = await axios.post(
        `${globalVariables.domain}/user/recover_password`,
        {
          email: data.email,
        }
      );
      if (response.data.success === true) {
        // console.log(response.data.content);
        setStep1Complete(true);
      } else {
        setMessage("Email not found");
        setModalStatus(true);
      }
    } catch (error) {
      // setSpinner(false);
      console.log(error);
    }
  };

  const onSubmitCode = async (data) => {
    console.log(data);

    try {
      const response = await axios.post(
        `${globalVariables.domain}/user/verify_code`,
        {
          code: data.code,
        }
      );
      if (response.data.success === true) {
        if (response.data.content === "Expired code") {
          // console.log("Expired code");
          setMessage(response.data.content);
          setModalStatus(true);
        } else if (response.data.content === "Invalid code") {
          setMessage(response.data.content);
          setModalStatus(true);
        } else {
          console.log(response.data.content);
          setStep2Complete(true);
        }
      } else {
        setMessage("Code not found");
        setModalStatus(true);
        // console.log(response.data.content);
        // console.log("algo 2");
      }
    } catch (error) {
      // setSpinner(false);
      console.log(error);
    }
  };

  const onSubmitNewPassword = async (data) => {
    console.log(data);
    try {
      const response = await axios.patch(
        `${globalVariables.domain}/user/password`,
        {
          email: email,
          password: data.password,
        }
      );
      if (response.data.success === true) {
        window.location.reload();
        // console.log("siuuuu");
        // console.log(response.data.content);
      }
    } catch (error) {
      // setSpinner(false);
      console.log(error);
    }
  };

  return (
    <>
      <ModalResponsePassword
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        message={message}
      />
      <div className="container">
        <div className="form-container">
          {!step1Complete && (
            <div>
              <form onSubmit={handleSubmit(onSubmitEmail)}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Please enter a valid email address",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <label>Email</label>
                      <input {...field} className="input_field" type="text" />

                      {fieldState?.error?.message && (
                        <div className="flex items-center mt-2">
                          <span className="text-red-500 red">
                            {fieldState?.error?.message}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                />
                <button
                  type="submit"
                  className="submit_button_wrapper input_submit"
                >
                  Continue
                </button>
              </form>
            </div>
          )}

          {!step2Complete && step1Complete && (
            <div>
              <svg
                onClick={() => setStep1Complete(false)}
                width={20}
                height={20}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
              >
                <path
                  d="M32 15H3.41l8.29-8.29-1.41-1.42-10 10a1 1 0 0 0 0 1.41l10 10 1.41-1.41L3.41 17H32z"
                  data-name="4-Arrow Left"
                />
              </svg>
              <form onSubmit={handleSubmit(onSubmitCode)}>
                <Controller
                  name="code"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Is required" }}
                  render={({ field, fieldState }) => (
                    <>
                      <label>Please enter the code sent to:</label>
                      <p>{email}</p>
                      <input
                        {...field}
                        maxLength={6}
                        className="input_field"
                        type="number"
                      />

                      {fieldState?.error?.message && (
                        <div className="flex items-center mt-2">
                          <span className="text-red-500 red">
                            {fieldState?.error?.message}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                />
                <button
                  type="submit"
                  className="submit_button_wrapper input_submit"
                >
                  Continue
                </button>
              </form>
            </div>
          )}
          {step2Complete && (
            <div>
              <form onSubmit={handleSubmit(onSubmitNewPassword)}>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{ required: `Is required` }}
                  render={({ field, fieldState }) => (
                    <>
                      <label>Password</label>
                      <input
                        {...field}
                        className="input_field"
                        type="password"
                      />

                      {fieldState?.error?.message && (
                        <div className="flex items-center mt-2">
                          <span className="text-red-500 red">
                            {fieldState?.error?.message}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                />

                <Controller
                  name="confirm_password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: `Is required`,
                    validate: (value) => {
                      const passwordFieldValue =
                        control._fields?.password?._f.value;
                      return (
                        passwordFieldValue === value || "Passwords must match"
                      );
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <label>Confirm Password</label>
                      <input
                        {...field}
                        className="input_field"
                        type="password"
                      />

                      {fieldState?.error?.message && (
                        <div className="flex items-center mt-2">
                          <span className="text-red-500 red">
                            {fieldState?.error?.message}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                />
                <button
                  type="submit"
                  className="submit_button_wrapper input_submit"
                >
                  Continue
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default RecoverPassword;
