import React, { useState, useEffect } from "react";

import "../styles/modal.css";

function ModalResponse({ modalStatus, setModalStatus, email }) {
  return (
    <>
      {modalStatus === true && (
        <>
          <div class="notifications-container modal-main">
            <div class="success">
              <div class="flex">
                <div class="flex-shrink-0">
                  <svg
                    class="succes-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <div class="success-prompt-wrap">
                  <p class="success-prompt-heading">Completed</p>
                  <div class="success-prompt-prompt">
                    <p>
                      Thank you for submitting the form! An email has been sent
                      to {email}
                    </p>
                  </div>
                  <div class="success-button-container">
                    <button
                      onClick={() => {
                        setModalStatus(false);
                        window.location.reload();
                      }}
                      type="button"
                      class="success-button-main"
                    >
                      Accept
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              setModalStatus(false);
              window.location.reload();
            }}
            className="opacity-25 fixed overflow-hidden inset-0 z-40 bg-black"
          ></div>
        </>
      )}
    </>
  );
}

export default ModalResponse;
