import React from "react";
import Form_potencial_users from "../form/form_potencial_users";

export default function FormPotentialUserCentralValley() {
  const clinic_id = "65a3aa83623c3dbb797a8f99";

  return (
    <div className="w-full flex justify-center items-center">
      <Form_potencial_users clinic_id={clinic_id} />
    </div>
  );
}
